var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.$store.state.auth.data.user.userBrokerId)?_c('div',[_c('p',{staticClass:"ma-5"},[_vm._v("Votre compte doit être lié à un courtier afin d'effectuer une demande.")])]):_c('div',[_c('validation-observer',{ref:"taskObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"vid":"typeDemande","name":"type de demande","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"label":"Type de demande *","outlined":"","clearable":"","hide-details":"","items":_vm.taskNatures,"item-text":"libelle","item-value":"code","return-object":""},model:{value:(_vm.typeDemande),callback:function ($$v) {_vm.typeDemande=$$v},expression:"typeDemande"}}),_c('span',{attrs:{"error-messages":errors}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialogEffect",attrs:{"return-value":_vm.pickerDateEffect.data,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.pickerDateEffect, "data", $event)},"update:return-value":function($event){return _vm.$set(_vm.pickerDateEffect, "data", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"pickerDateEffect","name":"Date d'effet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.pickerDateEffect.data),"append-icon":"date_range","label":"Date souhaitée / date d'effet *","readonly":"","outlined":"","hide-details":"","clearable":"","error-messages":errors,"required":"","clear-icon":"clear"},on:{"click:clear":function($event){_vm.pickerDateEffect.data = ''}}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.modalDateEffect),callback:function ($$v) {_vm.modalDateEffect=$$v},expression:"modalDateEffect"}},[_c('v-date-picker',{attrs:{"scrollable":"","format":"dd/mm/yyyy","label":"Date souhaitée / date d'effet *","error-messages":_vm.errors,"locale":"fr-fr","outlined":"","header-color":"tertiary","color":"tertiary_light","first-day-of-week":"1"},model:{value:(_vm.pickerDateEffect.data),callback:function ($$v) {_vm.$set(_vm.pickerDateEffect, "data", $$v)},expression:"pickerDateEffect.data"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.modalDateEffect = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$refs.dialogEffect.save(_vm.pickerDateEffect.data)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"sujet","name":"Sujet","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Sujet *","outlined":"","required":"","hide-details":"","clearable":""},model:{value:(_vm.sujet),callback:function ($$v) {_vm.sujet=$$v},expression:"sujet"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Commentaire","outlined":"","hide-details":""},model:{value:(_vm.commentaire),callback:function ($$v) {_vm.commentaire=$$v},expression:"commentaire"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-file-input',{attrs:{"rules":_vm.rules,"error-messages":_vm.errors,"label":"Fichier(s) la taille maximum autorisée par fichier est de 20 MB","prepend-icon":"","accept":"image/*, .pdf","prepend-inner-icon":"attach_file","small-chips":"","clearable":"","counter":"","multiple":"","outlined":"","show-size":"","hide-details":""},model:{value:(_vm.filesInput),callback:function ($$v) {_vm.filesInput=$$v},expression:"filesInput"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required","vid":"active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-radio',{attrs:{"label":"Votre demande concerne un contrat ou un devis existant","value":1},on:{"click":function($event){_vm.modalContratSearch = true}}}),_c('v-radio',{attrs:{"label":"Votre demande concerne un sociétaire","value":0,"error-messages":errors},on:{"click":function($event){_vm.modalSocietaireSearch = true}}})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"clientDetail.fullname","name":"Nom du sociètaire","rules":"required_if:contratDetail.number,null,false,undefined"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nom du sociètaire *","outlined":"","hide-details":"","disabled":""},model:{value:(_vm.clientDetail.fullname),callback:function ($$v) {_vm.$set(_vm.clientDetail, "fullname", $$v)},expression:"clientDetail.fullname"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"contratDetail.number","name":"Numéro du contrat","rules":"required_if:clientDetail.fullname,null,false,undefined"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Numéro du contrat","outlined":"","hide-details":"","disabled":""},model:{value:(_vm.contratDetail.number),callback:function ($$v) {_vm.$set(_vm.contratDetail, "number", $$v)},expression:"contratDetail.number"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"button_color--text",attrs:{"color":"button","disabled":invalid,"loading":_vm.loading},on:{"click":_vm.createTaskSubmit}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("done")]),_vm._v("Valider ")],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.modalContratSearch),callback:function ($$v) {_vm.modalContratSearch=$$v},expression:"modalContratSearch"}},[_c('v-card',{staticClass:"pa-10"},[_c('ContratSearch',{attrs:{"isModal":true},on:{"retrieveContrat":_vm.retrieveContrat}})],1)],1),_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.modalSocietaireSearch),callback:function ($$v) {_vm.modalSocietaireSearch=$$v},expression:"modalSocietaireSearch"}},[_c('v-card',{staticClass:"pa-10"},[_c('ClientSearch',{attrs:{"isModal":true},on:{"retrieveClient":_vm.retrieveClient}})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.message.type,"multi-line":true,"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"button_color--text","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }