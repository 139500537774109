var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Modifier l'adresse email et le téléphone ")]),_c('validation-observer',{ref:"mailTelobs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.modifyEmailAndTel.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"pa-5"},[_c('validation-provider',{attrs:{"vid":"selectMail","name":"type d'email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.mailType,"item-text":"libelle","item-value":"code","placeholder":"Type d'adresse email *","return-object":"","error-messages":errors,"outlined":"","clearable":"","hide-selected":""},model:{value:(_vm.selectMail),callback:function ($$v) {_vm.selectMail=$$v},expression:"selectMail"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"email","name":"adresse email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Adresse email *","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Modifier l'adresse email pour tous les contrats"},model:{value:(_vm.allContractEmail),callback:function ($$v) {_vm.allContractEmail=$$v},expression:"allContractEmail"}}),_c('validation-provider',{attrs:{"vid":"selectTel","name":"type de téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.telType,"item-text":"libelle","item-value":"code","placeholder":"Type de téléphone *","return-object":"","error-messages":errors,"outlined":"","clearable":"","hide-selected":""},model:{value:(_vm.selectTel),callback:function ($$v) {_vm.selectTel=$$v},expression:"selectTel"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"tel","name":"téléphone","rules":"required|isPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Téléphone *","outlined":""},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})]}}],null,true)}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Modifier le téléphone pour tous les contrats"},model:{value:(_vm.allContractTel),callback:function ($$v) {_vm.allContractTel=$$v},expression:"allContractTel"}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"J'accepte de recevoir les SMS"},model:{value:(_vm.smsAccepted),callback:function ($$v) {_vm.smsAccepted=$$v},expression:"smsAccepted"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" fermer ")]),_c('v-btn',{staticClass:"button_color--text",attrs:{"color":"button","type":"submit","disabled":invalid,"loading":_vm.loadingUpdateMailTel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("done")]),_vm._v("Valider ")],1)],1)],1)]}}])})],1),_c('v-snackbar',{attrs:{"color":_vm.message.type,"multi-line":true,"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"button_color--text","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }