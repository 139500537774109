<template>
  <div>
    <v-form v-model="valid" ref="updClientForm">
      <ValidationObserver ref="clientValidator">
      <v-row>
        <v-text-field v-show="false" v-model="client.id"></v-text-field>
        <v-text-field v-show="false" v-model="client.personType"></v-text-field>
        <v-text-field v-show="false" v-model="client.personCategory"></v-text-field>
         <v-col cols="3">
          <v-select
            :items="itemsLists['legalStatuses']"
            v-model="client.legalStatus"
            return-object
            item-text="libelle"
            item-value="code"
            label="Forme Juridique"
            outlined
            :loading="getClientLoading || listLoading"
            required
            :rules="[(v) => !!v, (v) => v.length > 0 || Object.keys(v).length > 0]"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.socialReason"
            label="Raison Sociale"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :loading="getClientLoading"
            :rules="[(v) => !!v]"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.tradeName"
            label="Nom commercial"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.siret"
            label="Siret"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.siren"
            label="Siren"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="client.address.adress1"
            label="Adresse"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :loading="getClientLoading"
            :rules="[(v) => !!v]"
          ></v-text-field>
        </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="code postal"
              rules="required|integer|length:5"
              vid="zipCode"
            >
              <v-text-field
                v-model="client.address.zipCode"
                label="CP *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
                :loading="getClientLoading"
              />
            </ValidationProvider>
          </v-col>

          <!-- Fix rule with zip code  -->
          <v-col cols="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="ville"
              rules="required" 
            >
              <v-select
                :items="itemsLists['citiesForPerson']"
                v-model="client.address.city"
               
                item-text="libelle"
                item-value="libelle"
                label="Ville *"
                outlined
                :loading="getCitiesLoading || getClientLoading"
                :disabled="itemsLists['citiesForPerson'] == 0"
                :error-messages="itemsLists['citiesForPerson'] == 0?'':errors"
              />
            </ValidationProvider>
          </v-col>
        
      </v-row>
      <v-row>
       
        <v-col cols="4" sm="3">
          <v-select
            :items="itemsLists['countries']"
            v-model="client.address.country"
            return-object
            item-text="libelle"
            item-value="code"
            label="Pays"
            outlined
            required
            :loading="getClientLoading || listLoading"
            :rules="[(v) => !!v, (v) => v.length > 0 || Object.keys(v).length > 0]"
          ></v-select>
        </v-col>
        <v-col cols="4" sm="3">
          <v-text-field
            v-model="client.effectif"
            label="Effectif"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="4" sm="3">
          <v-text-field
            v-model="client.webSite"
            label="Site Web"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
        <!--  <v-spacer /> -->
        
        
      </v-row>
      <v-row>
        <v-col cols="8" sm="3">
          <v-text-field
            v-model="client.mail.mail"
            label="Adresse mail"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="8" sm="3">
          <v-text-field
            v-model="client.phone.number"
            label="Numéro de téléphone"
            v-mask="'## ## ## ## ##'"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            required
            :rules="[(v) => !!v]"
            :loading="getClientLoading"
          ></v-text-field>
        </v-col>

        <v-col cols="4" sm="3">
          <v-checkbox
            true-value="true"
            false-value="false"
            :input-value="client.phone.acceptSms"
            hide-details
            color='button_light'
          >
            <template v-slot:label>
              <div>Accepte SMS</div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col v-if="this.isEstimate == true" cols="6" sm="3">
          <v-dialog v-model="searchDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn block large color="button" class="button_color--text" v-bind="attrs" v-on="on">
                <v-icon left>search</v-icon>Rechercher un sociétaire
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Rechercher un sociétaire</span>
              </v-card-title>

              <v-card-text>
                <ClientSearch :isEstimate="true" @update-client="updateClient" personTypeToSearch="MOR"/>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>

        <v-col v-if="this.isEstimate == true" cols="6" sm="3">
          <v-btn block large color="button" class="button_color--text" @click="nextStep">
            <v-icon left>right</v-icon>Suivant
          </v-btn>
        </v-col>
        <v-col v-if="this.isEstimate == false" cols="6" sm="3">
          <v-btn block large color="button" class="button_color--text" @click="addClient">
            <v-icon left>add</v-icon>
            {{
            this.clientId == "-1"
            ? "Nouveau sociétaire"
            : "Modifier sociétaire"
            }}
          </v-btn>
        </v-col>
      </v-row>
     </ValidationObserver>
    </v-form>
  </div>
</template>

<style scoped>
</style>

<script>
import ClientSearch from "../client/Search.vue";
import {
  required,
  integer,
  length
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre",
});
extend("length", {
  ...length,
  message: "Le champ {_field_} doit contenir {length} chiffres",
});
export default {
  name: "CreationModificationMoralPerson",
  components: {
    ClientSearch,
    ValidationObserver, ValidationProvider 
  },
  data: () => ({
    valid: false,
    searchDialog: false,
    menuBirthDate: false,
    formatedBirthDate: null,
  }),
  props: {
    isEstimate: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: "-1",
    },
  },
  created() {
    this.getClientItemsLists();
  },
  mounted() {},
  computed: {
    ...mapState({
      listLoading: (state) => state.estimation.loader["listClient"],
      getClientLoading: (state) => state.estimation.loader["getClient"],
      getCitiesLoading: (state) => state.estimation.loader["listCitiesForPerson"],
      itemsLists: (state) => state.estimation.itemsLists,
      client: (state) => state.estimation.estimate.person,
    }),
    /* birthDate: {
      get: function () {
        return this.client.birthDate;
      },
      set: function (v) {
        if (v) {
          this.$store.commit("estimation/updBirthDate", v);
        }
      },
    }, */
  },
  methods: {
    ...mapActions("estimation", ["getClientItemsLists", "fetchClient", "getCitiesListForPerson"]),
    verifyAge(dob) {
      if (dob != undefined) {
        var today = new Date();
        var parts = dob.split("/");
        var birthDate = new Date(
          parseInt(parts[2], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[0], 10)
        );

        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age >= 18 && age <= 77;
      } else return false;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString();
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    nextStep() {
      if (this.$refs["updClientForm"].validate()) {
        this.$store.commit("estimation/nextEstimationStep");
      }
    },
    addClient() {
      "To Implement";
    },
    async updateClient(clientId) {
      this.searchDialog = false;
      await this.fetchClient(clientId);
    },
    capitalizeFirstLetter: (str) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },
    capitalize: (str) => {
      return str ? str.toUpperCase() : "";
    },
  },
  watch: {
    "client.birthDate": {
      handler(val) {
       console.log('birthdate client')
      this.formatedBirthDate = this.formatDate(val);
      },
      deep: true
    },
    //Au changement du code postal, supprime la ville et le tableau de choix des villes
    "client.address.zipCode": {
      handler(val) {
        //logger.info(this.$refs.clientValidator.validate() && this.$refs.clientValidator);
        if(val === null || val === ''){
          this.client.address.city = "";
          this.itemsLists['citiesForPerson'] = []
        } else  this.getCitiesListForPerson(val);
        /* {
          this.$refs.clientValidator.validate().then(success => { 
            if (this.$refs.clientValidatorerrors.errors.zipCode.length == 0) 
              this.getCitiesListForPerson(val);
          }); 
        }*/
      },
      deep: true,
      immediate: true
    },
    "client.socialReason": function (newValue) {
      this.client.socialReason = this.capitalize(newValue);
    },
    /* "client.lastname": function (newValue) {
      this.client.lastname = this.capitalize(newValue);
    },
    "client.profession": function (newValue) {
      this.client.profession = this.capitalizeFirstLetter(newValue);
    }, */
  },
};
</script>
