import isAuthenticated from "@/router/helpers/isAuthenticated";

import DefaultLayout from "@/components/layout/DefaultLayout";

import Home from "../views/Home";
import Thankyou from "../views/Thankyou";
import ContractSearch from "../views/contract/Search";
import ClientSearch from "../views/client/Search";
import Client from "../views/client/Client";
import Contract from "../views/contract/Contract";

import UpdateMyProfile from "@/views/account/UpdateMyProfile";

import UserSearch from "../views/user/Search";
import UserUpdate from "../views/user/Update";
import UserAdd from "../views/user/Add";
import MemberSearch from "../views/member/Search";

import Gamuts from "../views/gamuts/Gamuts";

import Estimate from "../views/estimate/Estimate";

import PageSearch from "../views/page/Search";
import PageUpdate from "../views/page/Page";
import PageView from "../views/page/View";
import FolderSearch from "../views/folder/Search";
import FolderUpdate from "../views/folder/Folder";
import DocumentSearch from "../views/document/Search";
import DocumentUpdate from "../views/document/Document";
import DocumentView from "../views/document/View";

import Tasks from "../views/task/Tasks";
import TaskSearch from "../views/task/Search";

import CreateContract from "../views/estimate/CreateContract";

const AppRoute = {
  path: "/",
  component: DefaultLayout,
  beforeEnter: isAuthenticated,
  children: [
    { path: "/", name: "Accueil", component: Home },
    {
      path: "/contract/search",
      name: "Recherche de devis et contrats",
      component: ContractSearch,
      meta: { keepAlive: true },
    },
    {
      path: "/contract/topSearch",
      name: "Recherche de devis et contrats",
      component: ContractSearch,
    },
    {
      path: "/client/search",
      name: "Recherche de sociétaires",
      component: ClientSearch,
      meta: { keepAlive: true },
    },
    {
      path: "/client/search/:id",
      name: "Fiche sociétaire",
      component: Client,
    },
    {
      path: "/contract/search/:id",
      name: "Détail du contrat",
      component: Contract,
    },
    { path: "/profile", name: "Mon profil", component: UpdateMyProfile },
    {
      path: "/estimate/:productId",
      name: "Tarificateur",
      component: Estimate,
    },
    {
      path: "/estimate/:estimateId",
      name: "Dupliquer un devis",
      component: Estimate,
    },
    {
      path: "/user/search",
      name: "Rechercher un utilisateur",
      component: UserSearch,
    },
    {
      path: "/user/search/:id",
      name: "Modifier un utilisateur",
      component: UserUpdate,
    },
    {
      path: "/member/search",
      name: "Rechercher un sociétaire",
      component: MemberSearch,
    },
    { path: "/user/add", name: "Ajouter un utilisateur", component: UserAdd },
    { path: "/gamuts", name: "Parametrage Gammes", component: Gamuts },
    { path: "/page/search", name: "Gérer mes pages", component: PageSearch },
    {
      path: "/page/search/:id",
      name: "Gérer ma page",
      component: PageUpdate,
    },
    { path: "/page/:id", name: "Ma page", component: PageView },
    {
      path: "/folder/search",
      name: "Gérer mes dossiers",
      component: FolderSearch,
    },
    {
      path: "/folder/search/:id",
      name: "Gérer mon dossier",
      component: FolderUpdate,
    },
    {
      path: "/file/search",
      name: "Gérer mes fichiers",
      component: DocumentSearch,
    },
    {
      path: "/file/search/:id",
      name: "Gérer mon fichier",
      component: DocumentUpdate,
    },
    { path: "/document", name: "Base documentaire", component: DocumentView },
    {
      path: "/task/creation",
      name: "Effectuer une demande",
      component: Tasks,
    },
    {
      path: "/task/search",
      name: "Suivre mes demandes",
      component: TaskSearch,
    },
    {
      path: "/contract/create",
      name: "Transformer un devis en contrat",
      component: CreateContract,
      props: true,
    },
  ],
};
export default AppRoute;
