var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.headers,"items":_vm.secondariesAssured},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button_color--text mb-2",attrs:{"large":"","color":"button"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v("Ajouter des assurés additionnels ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[(_vm.dialog === true)?_c('ValidationObserver',{ref:"form"},[_c('v-form',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"civilité"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.itemsListsClient['civilities'],"item-text":"libelleCourt","item-value":"code","return-object":"","label":"Civilité","outlined":"","required":"","error-messages":errors},model:{value:(_vm.editedSecondaryAssured.person.civility),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "civility", $$v)},expression:"editedSecondaryAssured.person.civility"}})]}}],null,false,1687340998)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nom","outlined":"","clearable":"","clear-icon":"clear","required":"","error-messages":errors},model:{value:(_vm.editedSecondaryAssured.person.lastname),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "lastname", $$v)},expression:"editedSecondaryAssured.person.lastname"}})]}}],null,false,1428769413)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"prénom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Prénom","outlined":"","clearable":"","clear-icon":"clear","required":"","error-messages":errors},model:{value:(_vm.editedSecondaryAssured.person.firstname),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "firstname", $$v)},expression:"editedSecondaryAssured.person.firstname"}})]}}],null,false,249076830)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"relation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.itemsListsProduct['relations'],"item-text":"libelle","item-value":"code","return-object":"","label":"Relation","outlined":"","required":"","error-messages":errors},model:{value:(_vm.editedSecondaryAssured.relationRole),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured, "relationRole", $$v)},expression:"editedSecondaryAssured.relationRole"}})]}}],null,false,2919299130)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"situation matrimoniale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.itemsListsClient['maritalStatuses'],"return-object":"","item-text":"libelle","item-value":"code","label":"Sit. Mat.","outlined":"","required":"","error-messages":errors},model:{value:(_vm.editedSecondaryAssured.person.maritalStatus),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "maritalStatus", $$v)},expression:"editedSecondaryAssured.person.maritalStatus"}})]}}],null,false,4052226482)})],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-menu',{ref:"menuBirthDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required","name":"date de naissance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date de naissance ","outlined":"","persistent-hint":"","clearable":"","clear-icon":"clear","required":"","error-messages":errors},on:{"click:clear":function($event){_vm.editedSecondaryAssured.person.birthDate = ''},"blur":function($event){_vm.$set(_vm.editedSecondaryAssured.person,'birthDate', _vm.parseDate(_vm.formatedBirthDate))}},model:{value:(_vm.formatedBirthDate),callback:function ($$v) {_vm.formatedBirthDate=$$v},expression:"formatedBirthDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,343858650),model:{value:(_vm.menuBirthDate),callback:function ($$v) {_vm.menuBirthDate=$$v},expression:"menuBirthDate"}},[_c('v-date-picker',{attrs:{"scrollable":"","header-color":"tertiary","color":"tertiary_light","first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menuBirthDate = false}},model:{value:(_vm.editedSecondaryAssured.person.birthDate),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "birthDate", $$v)},expression:"editedSecondaryAssured.person.birthDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Profession","outlined":"","clearable":"","clear-icon":"clear"},model:{value:(_vm.editedSecondaryAssured.person.profession),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "profession", $$v)},expression:"editedSecondaryAssured.person.profession"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"catégorie socioprofessionnelle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.itemsListsClient['csps'],"return-object":"","item-text":"libelle","item-value":"code","label":"Cat. Socioprofessionnelle","outlined":"","required":"","error-messages":errors,"loading":_vm.loader},model:{value:(_vm.editedSecondaryAssured.person.socioProfessionalCategory),callback:function ($$v) {_vm.$set(_vm.editedSecondaryAssured.person, "socioProfessionalCategory", $$v)},expression:"editedSecondaryAssured.person.socioProfessionalCategory"}})]}}],null,false,1522895243)})],1)],1)],1)])],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Sauvegarder")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Etes vous sûr de vouloir supprimer cette entrée ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.person.birthDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.person.birthDate)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }