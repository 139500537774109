<template>
  <div>
    <v-stepper v-model="riskStep" vertical>
      <v-stepper-step :complete="riskStep > 1" editable step="1">
        Conditions Générales
      </v-stepper-step>

      <v-stepper-content step="1">
        <CG />
      </v-stepper-content>

      <v-stepper-step
        :complete="riskStep > 2"
        :editable="riskStep > 1"
        step="2"
      >
        Informations Véhicule
      </v-stepper-step>

      <v-stepper-content step="2">
        <Vehicle :productRisk="productRisk" />
      </v-stepper-content>

       <v-stepper-step
        :complete="riskStep > 3"
        :editable="riskStep > 2"
        step="3"
      >
        Informations Conducteurs
      </v-stepper-step>

      <v-stepper-content step="3">
        <Drivers v-if="subscriber.personType == 'PHY'" :productRisk="productRisk" :subscriber="subscriber" :personItemsLists="personItemsLists"/>
        <MoralPersonDrivers v-else :productRisk="productRisk" :subscriber="subscriber" :personItemsLists="personItemsLists"/>
      </v-stepper-content>
      
      
    </v-stepper>
  </div>
</template>

<style  scoped>
</style>

<script>

import CG from "./autoSteps/CG.vue";
import Vehicle from "./autoSteps/Vehicle.vue";
import Drivers from "./autoSteps/Drivers.vue";
import MoralPersonDrivers from "./autoSteps/MoralPersonDrivers.vue";

import { mapActions, mapState } from "vuex";
//import autoStoreModule from "../../store/estimation/auto.store";


export default {
  name: "RiskAuto",
  components: {  CG, Vehicle, Drivers, MoralPersonDrivers},
  props: {
    subscriber: Object,/*{
       type: Object,
      //default for test purpose
      default: () => {},
    }, */
    productRisk: Object,
    personItemsLists: Object,
  },
  data: () => ({
  }),
  async created() {
  },
  mounted() {
    
  },
  computed: {
    ...mapState({
      /* vehicle: (state) => state.estimation.estimate.productRisk.vehicle,
      vehicleInfos: (state) => state.estimation.estimate.productRisk.vehicleInfos, */
      /* itemsListsProduct: (state) => state.productRisk.itemsLists,
      listLoader: (state) => state.productRisk.loaders.gavLists, */
    }),
    riskStep: {
      get: function () {return this.$store.state.estimation.riskStep},
      set: function (v) { this.$store.commit('estimation/updRiskStep', v)},
    }
  },
  methods: {
  /*   ...mapActions("productRisk", [
      "getDriverItemsLists",
      "getAutoOptsItemsLists",
    ]), */
  },
  watch: {
  },
};
</script>









 