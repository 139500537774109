import Vue from "vue";
import Vuetify from "vuetify";

import fr from "vuetify/lib/locale/fr";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

let options = {
    lang: {
        locales: {
            fr
        },
        current: "fr"
    },
    icons: {
        iconfont: "md"
    },
    theme: {
        // dark: true,
        options: {
            customProperties: true,
            variations: false
        },
        themes: {
            light: {
                primary: "#000",

                secondary: "#424242",
                accent: "#82B1FF",
                anchor: "#000",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",

                tertiary: "#004d93", //#000
                tertiary_light: "#5999d4", //#999

                app_bar_bottom: "#ed6d30", //#fff

                drawer_background: "#ed6d30", //#fff
                drawer_color: "#fff", //#000

                button: "#ed6d30", //#000
                button_light: "#fc9463", //#999
                button_color: "#fff", //#fff

                list_background: "#bdbdbd",
                list_background_light: "#e0e0e0",
                list_background_lighter: "#fafafa",
                list_color: "#fff",
            },
            dark: {
                primary: "#fff",
                tertiary: "#424242",
                tertiary_light: "#727272",
                app_bar_bottom: "#fff",
            },
        },
    },
};

export default new Vuetify(options);
