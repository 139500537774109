<template>
  <div>
    <v-row>
      <v-col v-for="image in images" :key="image.title" cols="12" sm="4">
        <!-- <v-col class="pa-0"> -->
        <router-link v-if="image.target=='_self'" :to="image.route" :target="image.target">
          <v-img
            lazy-src=""
            :aspect-ratio="image.ratio"
            :src="require(`@/assets/images/${image.src}`)"
          ></v-img>
        </router-link>
        <a v-else :href="image.route" :target="image.target">
          <v-img
              lazy-src=""
              :aspect-ratio="image.ratio"
              :src="require(`@/assets/images/${image.src}`)"
            ></v-img>
        </a>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list :subheader="true" color="list_background_lighter">
          <v-subheader class="rounded-t-lg text-h6 tertiary list_color--text"
            >Nos gammes</v-subheader
          >

          <v-list-group
            v-for="gamut in gamuts"
            :key="gamut.title"
            v-model="gamut.active"
            color="tertiary"
            active-class="list_background_light"
          >
            <v-icon
              slot="prependIcon"
              color="tertiary_light"
              v-text="gamut.icon"
            ></v-icon>
            <v-icon slot="appendIcon" color="tertiary_light"
              >expand_more</v-icon
            >

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="gamut.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="product in gamut.products"
              :key="product.title"
              link
            >
              <v-list-item-content>
                <v-list-item-title v-text="product.title"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action
                v-for="(action, index) in product.actions"
                :key="action.route"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                      @click="
                        index == 0
                          ? goToEstimate(action.route)
                          : getDataFromApi(action.productFileId)
                      "
                      :disabled="action.hasFile != null && !action.hasFile"
                      :loading="loading[action.productFileId]"
                    >
                      <v-icon
                        color="tertiary_light"
                        v-text="action.icon"
                      ></v-icon>
                    </v-btn>

                    <!-- Not for last item  -->
                    <v-divider
                      vertical
                      class="mx-2"
                      v-if="index !== product.actions.length - 1"
                    ></v-divider>
                  </template>
                  <span v-text="action.tooltip"></span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>

      <v-col>
        <v-list two-line :subheader="true" color="list_background_lighter">
          <v-subheader class="rounded-t-lg text-h6 tertiary list_color--text"
            >Mon tableau de bord</v-subheader
          >

          <v-skeleton-loader v-if="firstLoad" type="list-item@4" class="mt-5">
          </v-skeleton-loader>

          <v-list-item-group>
            <v-list-item
              v-for="item in dashboard"
              :key="item.title"
              :prepend-icon="item.icon"
              inactive
            >
              <v-list-item-avatar class="tertiary_light list_color--text">
                <div class="numberCircle" v-text="item.count"></div>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
                <v-list-item-subtitle v-html="item.text"></v-list-item-subtitle>
              </v-list-item-content>

              <!-- non cliquable, pas de tooltip en attendant de faire un lien faire la recherche contrat avec les bons params -->
              <v-tooltip left v-if="item.tooltip && item.count">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-action>
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :to="item.route"
                  >
                    <v-icon color="tertiary_light" v-text="item.icon"></v-icon>
                  </v-btn>
                  </v-list-item-action>
                </template>
                <span v-text="item.tooltip"></span>
              </v-tooltip>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
/* Display inline for actions buttons icons in products lists */
div.v-list-item__action.v-list-item__action--stack {
  display: block;
}

/* Remove last button icon left margin in lists */
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child) {
  margin-left: 0;
}

.numberCircle {
  font-size: 18px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding-top: 14px;
  text-align: center;
}
</style>

<script>
import axios from "axios";
export default {
  name: "Home",
  data: () => ({
    appDir: process.env.VUE_APP_NAME,
    firstLoad: true,
    images: [
      {
        src: "services.jpg",
        ratio: "1.777",
        title: "Services",
        description: "Lorem ipsum",
        route: "/estimate/",
        target: "_self",
      },
      {
        src: "extranet.jpg",
        ratio: "1.777",
        title: "Extranet",
        description: "Lorem ipsum",
        route: "/estimate/",
        target: "_self",
      },
      {
        src: "formation.jpg",
        ratio: "1.777",
        title: "Training",
        description: "Lorem ipsum",
        route: "https://www.younited-credit.com/landingpage/cmam?ead-publisher=CMAM&ead-name=CMAM&ead-location=CMAM-1x1&ead-creative=clearpix&ead-creativetype=1x1&BusinessProviderCode=CMAM&utm_source=CMAM&utm_medium=LeadGen&utm_campaign=CMAM",
        target: "_blank",
      },
    ],
    dashboard: [],
    gamuts: [],
    loading: [],
  }),
  created() {
    this.getImagesPromise().then((data) => {
      this.images = data.images;
    });
    this.listGamutsPromise().then((data) => {
      this.gamuts = data.items;
    });
    this.getDashboardPromise().then((data) => {
      this.dashboard = data.dashboard;
      this.firstLoad = false;
    });
    //logger.info("Welcome HomeView !");
  },
  methods: {
    getImagesPromise() {
      return new Promise((resolve, reject) => {
        axios.get("/api/home/image").then((response) => {
          let images = response.data.images;
          resolve({
            images,
          });
        });
      });
    },
    getDashboardPromise() {
      return new Promise((resolve, reject) => {
        axios.get("/api/dashboard/get").then((response) => {
          let dashboard = response.data.dashboard;
          resolve({
            dashboard,
          });
        });
      });
    },
    listGamutsPromise() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/gamuts-menu")
          .then((response) => {
            let items = response.data.gamuts;

            resolve({
              items,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    getDataFromApi(productFileId) {
      this.loading[productFileId] = true;
      this.downloadFile(productFileId).then((data) => {
        if (data.response.data.size > 0) {
          this.loading = [];
        }
      });
    },
    downloadFile(productFileId) {
      var formData = new FormData();
      formData.append("productFileId", productFileId);
      return new Promise((resolve, reject) => {
        axios
          .post("/api/file/product", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          })
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              response.headers["x-suggested-filename"]
            );
            // fileLink.setAttribute("target", "_blank");
            document.body.appendChild(fileLink);

            fileLink.click();
            resolve({ response });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    goToEstimate(route) {
      this.$router.push(route);
    },
  },
  /* watch: {
    gamuts:
      function() {
        this.$forceUpdate();
      }
  } */
};
</script>
