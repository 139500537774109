<template>

  <v-card>
          <v-row>
            <v-col>
              <p style="color: blue">
                La validation des déclarations reprises ci-dessous fait partie
                intégrante du questionnaire de souscription que vous devrez
                compléter pour obtenir un devis. Le souscripteur et/ou son
                conjoint, concubin, partenaire pacsé (s'il est désigné comme
                2ème conducteur) déclare remplir les conditions de souscription
                suivantes :
              </p>
              <ul>
                <li>
                  Avoir entre 21 et 79 ans et 364 jours maximum au jour de la
                  date d'effet du contrat
                </li>
                <li>
                  Etre titulaire d'un permis B valide et permanent depuis 2
                  ans au moins, délivré par un pays de l’U.E. et conforme à la
                  réglementation française en vigueur
                </li>
                <li>
                  Le souscripteur doit être une personne physique ou une
                  personne morale
                </li>
                <li>
                  La carte grise doit être au nom du conducteur principal et
                  ou de son conjoint /concubin (ce dernier devant être désigné
                  dans ce cas au contrat) ou au nom d’une société de crédit ou
                  de leasing pour autant que le nom du souscripteur ou de son
                  conjoint/concubin apparaisse
                </li>
                <li>
                  Le véhicule doit posséder une immatriculation française :
                  tolérance de 30 jours pour les véhicules immatriculés à
                  l'étranger : dans ce cas, le niveau de garantie possible sera
                  en Responsabilité Civile uniquement en cas de dépôt d’une
                  demande de carte grise française conformément aux dispositions
                  légales.
                </li>
                <li>
                  Le véhicule doit être strictement de série courante et ne
                  pas avoir subi de transformations ou de modifications
                  notamment en ce qui concerne sa puissance et son poids
                </li>
                <li>
                  Le véhicule ne doit pas être utilisé pour le transport de
                  matières dangereuses, pour le transport public de voyageurs,
                  pour le transport public de marchandises, comme auto-école,
                  ambulance ou donné en location
                </li>
                <li>
                  Ne pas être atteint de maladie grave ou d’infirmité
                  légalement incompatible avec le maintien ou la délivrance du
                  permis de conduire
                </li>
                <li>
                  Ne pas avoir fait l’objet d’une condamnation devant une
                  juridiction pénale pour infraction au code de la route durant
                  les 60 derniers mois pour délit de fuite après accident,
                  défaut d'assurance, conduite sans permis, refus d'obtempérer
                  ou conduite sous l'emprise de stupéfiants drogues ou
                  tranquillisants non prescrits médicalement ou refus de se
                  soumettre à un dépistage d'alcoolémie ou de stupéfiants
                </li>
                <li>
                  Ne pas avoir fait l'objet d'une nullité de contrat
                  automobile au cours des 36 derniers mois
                </li>
                <li>
                  Pour les véhicules dont le groupe est ≥ 36 et ou la classe
                  de prix ≥ Q, le véhicule doit être équipé d’un système de
                  protection antivol (au minimum système anti démarrage) agréé
                  SRA 6 à 7 clés, monté en usine ou posé par un professionnel,
                  pour pouvoir bénéficier de la garantie vol. Pour les véhicules
                  équipés de jantes en alliage ou en aluminium, le souscripteur
                  s’engage à fournir, en cas de vol d’une ou plusieurs roues, la
                  facture d’achat à son nom d’écrous antivol compatibles avec le
                  véhicule assuré. En l’absence de ce justificatif daté
                  antérieurement à la date du vol, l’indemnisation des dites
                  roues sera nulle.
                </li>
              </ul>
              <p style="color: blue">
                De plus, dans le cadre d'une tarification pour un risque
                classique, le souscripteur et/ou son conjoint, concubin,
                partenaire pacsé (s'il est désigné comme 2ème conducteur)
                déclare(nt) remplir les conditions de souscription suivantes :
              </p>
              <ul>
                <li>
                  Ne pas avoir fait l'objet d'une suspension de permis de plus
                  de 3 mois au cours des 36 derniers mois et ou d'un contrôle
                  d'alcoolémie positif au cours des 60 derniers mois
                </li>
              </ul>
            </v-col>
          </v-row>
          <ValidationObserver ref="observerDisclaimer" v-slot="{ valid }">
            <form @submit.prevent="nextStep">
              <v-row>
                <v-col>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="isTrue"
                    name="Veuillez accepter les conditions générales"
                   
                  >
                    <v-checkbox
                      v-model="disclaimer"
                      color="button_light"
                      label="L'assuré remplit les conditions de souscription énoncées ci-dessus"
                      :error-messages="errors"
                    />
                    
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer />
                <v-col>
                  <v-btn
                    block
                    large
                    color="button"
                    class="button_color--text"
                    type="submit"
                  >
                    Suivant
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card>
</template>

<style scoped>

</style>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState } from "vuex";

setInteractionMode("eager");

extend("isTrue", {
  validate(value) {
    return value;
  },
  message: "{_field_}",
});


export default {
  name: "CGAutoStep",
  components: {ValidationObserver, ValidationProvider },
  data: () => ({
    disclaimer: false,
  }),
  props: {
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    nextStep() {
      this.$refs["observerDisclaimer"].validate().then((valid) => {
        if(valid) {
          this.$store.commit('estimation/nextRiskStep')
        }
      });
    },
  },
  watch: {
  }
};
</script>
