<template>
  <v-card>
    <v-row>
      <v-col>
        <h2>Véhicule *</h2>
      </v-col>

      <v-col>
        <v-dialog v-model="searchVehicleDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn large color="button" class="button_color--text" v-bind="attrs" v-on="on">
              <v-icon left>search</v-icon> Rechercher
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Rechercher un véhicule</span>
            </v-card-title>

            <v-card-text>
              <SRA @update-vehicle="updateVehicle" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row v-show="Object.keys(productRisk.vehicle).length > 0">
      <v-col cols="6">
        <p>Marque: {{ productRisk.vehicle.brand }}</p>
        <p>Modèle: {{ productRisk.vehicle.model }}</p>
        <p>Version: {{ productRisk.vehicle.version }}</p>
        <p>Type Mines: {{ productRisk.vehicle.minesType }}</p>
        <p>Code SRA: {{ productRisk.vehicle.SRACode }}</p>
      </v-col>

      <v-col cols="6">
        <p>Energie: {{ productRisk.vehicle.energy }}</p>
        <p>Puissance administrative: {{ productRisk.vehicle.fiscalPower }}</p>
        <p>Carrosserie: {{ productRisk.vehicle.body }}</p>
      </v-col>
    </v-row>

    <ValidationObserver ref="observerVehicle" v-slot="{ valid }">
      <v-form>
        <!-- ----- ACQUISITION ----- -->
        <v-row>
          <v-col cols="3" sm="3">
            <h2>Acquisition / Usage</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <ValidationProvider v-slot="{ errors }" name="immatriculation" rules="required">
              <v-text-field v-model="productRisk.vehicleInfos.registration" label="Immatriculation *" outlined
                :error-messages="errors" clearable clear-icon="clear" required />
            </ValidationProvider>
          </v-col>
          <v-col cols="3" sm="3">
            <v-menu ref="menuFirstUseDate" v-model="menuFirstUseDate" :close-on-content-click="false"
              transition="scale-transition" offset-y max-width="290px" min-width="auto">

              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" name="date de 1ère mise en circulation" rules="required">
                  <v-text-field v-model="formatedFirstUseDate" v-mask="'##/##/####'"
                    label="Date de 1ère mise en circulation *" outlined prepend-icon="calendar" clearable
                    clear-icon="clear" @click:clear="
                      productRisk.vehicleInfos.firstUseDate = '';
                                          " v-bind="attrs" @blur="productRisk.vehicleInfos.firstUseDate = parseDate(formatedFirstUseDate)" v-on="on" required
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker v-model="productRisk.vehicleInfos.firstUseDate" scrollable header-color="tertiary" color="tertiary_light"
                first-day-of-week="1" no-title @input="menuFirstUseDate = false"></v-date-picker>
            </v-menu>

          </v-col>
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="usage" rules="required">
              <v-select :items="itemsLists.uses" v-model="productRisk.vehicleInfos.use" return-object item-text="libelle"
                item-value="code" label="Usage *" outlined :loading="selectsLoading" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="nombre de KM/an" rules="integer">
              <v-text-field v-model="productRisk.vehicleInfos.kmsByYear" label="Nombre de KM/an" outlined
                :error-messages="errors" clearable clear-icon="clear" />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-select :items="itemsLists.acquisitionModes" v-model="productRisk.vehicleInfos.acquisitionMode"
              return-object item-text="libelle" item-value="code" label="Mode d'acquisition" outlined
              :loading="selectsLoading" clearable clear-icon="clear" />
          </v-col>

          <v-col cols="3" sm="3">
            <v-menu ref="menuFirstUseDate" v-model="menuAcquisitionDate" :close-on-content-click="false"
              transition="scale-transition" offset-y max-width="290px" min-width="auto">

              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" name="date d'acquisition" rules="required">
                  <v-text-field v-model="formatedAcquisitionDate" label="Date d'acquisition *" outlined clearable
                    clear-icon="clear" @click:clear="productRisk.vehicleInfos.acquisitionDate = '';
                                          " v-bind="attrs"
                    @blur="productRisk.vehicleInfos.acquisitionDate = parseDate(formatedAcquisitionDate)" v-on="on"
                    required :error-messages="errors" v-mask="'##/##/####'"></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker v-model="productRisk.vehicleInfos.acquisitionDate" scrollable header-color="tertiary"
                color="tertiary_light" first-day-of-week="1" no-title
                @input="menuAcquisitionDate = false"></v-date-picker>
            </v-menu>

          </v-col>

          <!-- <v-col cols="2" sm="3">
            <v-text-field
              v-model="productRisk.vehicleInfos.drivingMode"
              label="Mode de conduite"
              outlined
              hide-details
              clearable
              clear-icon="clear"
            />
          </v-col> -->

          <v-col cols="2">

            <v-text-field :value="productRisk.vehicle.originalPrice" label="Prix d'origine" outlined clearable
              clear-icon="clear" disabled />

          </v-col>

          <v-col cols="4">
            <v-switch v-model="productRisk.vehicleInfos.used" color="button_light" label="Occasion ?" />
          </v-col>
        </v-row>

        <!-- ----- GARAGE ----- -->
        <v-row>
          <v-col cols="3" sm="3">
            <h2>Garage</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="code postal" rules="required|integer|length:5" vid="zipCode">
              <v-text-field v-model="productRisk.vehicleInfos.parkDetails.zipCode" label="CP *" outlined
                :error-messages="errors" clearable clear-icon="clear"
                @blur="errors.length === 0 ? getCitiesListForAuto() : ''" />
            </ValidationProvider>
          </v-col>

          <!-- Fix rule with zip code  -->
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="ville" rules="required">
              <v-select :items="itemsLists['cities']" v-model="productRisk.vehicleInfos.parkDetails.city" return-object
                item-text="ville" item-value="id" label="Ville *" outlined :loading="citiesLoading"
                :disabled="itemsLists['cities'] == 0" :error-messages="itemsLists['cities'] == 0 ? '' : errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <!-- ----- REMORQUE ----- -->
        <v-row>
          <v-col cols="3" sm="3">
            <h2>Remorque</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3" sm="3">
            <v-text-field v-model="productRisk.vehicleInfos.trailer.registration" label="Immatriculation" outlined
              hide-details clearable clear-icon="clear" />
          </v-col>

          <v-col cols="3">
            <v-select :items="itemsLists.trailerWeights" v-model="productRisk.vehicleInfos.trailer.weight" return-object
              item-text="libelle" item-value="code" label="Poids" outlined :loading="selectsLoading" clearable
              clear-icon="clear" />
          </v-col>

          <v-col cols="3">
            <v-select :items="itemsLists.trailerTypes" v-model="productRisk.vehicleInfos.trailer.type" return-object
              item-text="libelle" item-value="code" label="Type" outlined :loading="selectsLoading" clearable
              clear-icon="clear" />
          </v-col>
          <v-col cols="3" sm="3">
            <v-text-field v-model="productRisk.vehicleInfos.trailer.brand" label="Marque" outlined hide-details clearable
              clear-icon="clear" />
          </v-col>
          <v-col cols="3" sm="3">
            <v-text-field v-model="productRisk.vehicleInfos.trailer.model" label="Modèle" outlined hide-details clearable
              clear-icon="clear" />
          </v-col>
        </v-row>

        <!-- ----- COMPLEMENTS ----- -->
        <v-row>
          <v-col cols="3" sm="3">
            <h2>Compléments</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="nombre de véhicules par foyer" rules="integer|required">
              <v-text-field v-model="productRisk.vehicleInfos.householdVehiclesNb" label="Nb véhicules foyer *" outlined
                :error-messages="errors" clearable clear-icon="clear" />
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="nombre de permis par foyer" rules="integer">
              <v-text-field v-model="productRisk.vehicleInfos.householdDriverLicencesNb" label="Nb permis foyer" outlined
                :error-messages="errors" clearable clear-icon="clear" />
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="nombre de permis de - 3ans" rules="integer">
              <v-text-field v-model="productRisk.vehicleInfos.householdYoungDriverNb" label="Dont - de 3 ans" outlined
                :error-messages="errors" clearable clear-icon="clear" />
            </ValidationProvider>
          </v-col>

          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="appartenance véhicule" rules="required">
              <v-select :items="itemsLists.vehicleOwner" v-model="productRisk.vehicleInfos.owner" return-object
                item-text="libelle" item-value="code" label="Appartenance Veh. *" outlined :error-messages="errors"
                :loading="selectsLoading" />
            </ValidationProvider>
          </v-col>

          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="période sans assurance" rules="required">
              <v-select :items="itemsLists.withoutInsuranceRanges"
                v-model="productRisk.vehicleInfos.withoutInsuranceRange" return-object item-text="libelle"
                item-value="code" label="Veh. sans assurance ? *" outlined :loading="selectsLoading"
                :error-messages="errors" />
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="kilométrage" rules="integer">
              <v-text-field v-model="productRisk.vehicleInfos.km" label="Kilométrage" outlined :error-messages="errors"
                clearable clear-icon="clear" />
            </ValidationProvider>
          </v-col>
        </v-row>

        <!-- ----- SUIVANT ----- -->
        <v-row>
          <v-spacer />
          <v-col>
            <v-btn block large color="button" class="button_color--text" @click="nextStep">
              Suivant
            </v-btn>
            <ValidationProvider v-slot="{ errors }" rules="isTrue" name="Veuillez sélectionner un véhicule">
              <!-- <span>{{errors[0]}}</span> -->
              <v-input v-model="isVehicleSelected" :error-messages="errors" hidden />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<style scoped></style>

<script>
import {
  required,
  integer,
  length,
  double,
  between,
  alpha_num,
} from "vee-validate/dist/rules";
import SRA from "../../../components/SRA.vue";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
import axios from "axios";

import Vue from 'vue';

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre entier",
});
extend("length", {
  ...length,
  message: "Le champ {_field_} doit contenir {length} chiffres",
});
extend("double", {
  ...double,
  message: "Le champ {_field_} doit être une valeur à {decimals} décimale ",
});
extend("between", {
  ...between,
  message: "Le champ {_field_} doit être compris entre {min} et {max}",
});
extend("alpha_num", {
  ...alpha_num,
  message: "Le champ {_field_} doit être alpha-numérique",
});
/* extend("required_if", {
  ...required_if,
  message: "",
}); */

extend("isTrue", {
  validate(value) {
    //console.log('isTrue : '+value)
    return value;
  },
  message: "{_field_}",
});

export default {
  name: "VehicleStep",
  components: { SRA, ValidationObserver, ValidationProvider },
  data: () => ({
    searchVehicleDialog: false,

    menuFirstUseDate: false,
    formatedFirstUseDate: null,
    menuAcquisitionDate: false,

    formatedAcquisitionDate: null,

    //isVehicleSelected: false,

    selectsLoading: false,
    citiesLoading: false,
    itemsLists: {
      cities: []
    },
  }),
  props: {
    //vehicle: Object,
    productRisk: Object,
  },
  async created() {
    this.getVehicleItemsLists();
    if (this.productRisk.vehicleInfos.parkDetails.zipCode != "") {
      await this.getCitiesListForAuto();
      var result = this.itemsLists.cities.filter(city => {
        return city.ville == this.productRisk.vehicleInfos.parkDetails.city
      });
      this.productRisk.vehicleInfos.parkDetails.city = result[0];
    }
  },
  mounted() {
    this.formatedAcquisitionDate = this.formatDate(this.productRisk.vehicleInfos.acquisitionDate);
    this.formatedFirstUseDate = this.formatDate(this.productRisk.vehicleInfos.firstUseDate);
  },
  computed: {
    /* firstUseDate: {
      get: function () { return this.productRisk.vehicleInfos.firstUseDate },
      set: function (v) {
        if (v != undefined) {
          this.productRisk.vehicleInfos.firstUseDate = v;
        }
      },
    }, */
    /* formatedFirstUseDate() {
      return this.formatDate(this.productRisk.vehicleInfos.firstUseDate);
    }, */
    /* formatedAcquisitionDate: {
      get: function () { return this.formatDate(this.productRisk.vehicleInfos.acquisitionDate); },
      set: function (v) {
        console.log(v);
        if (v != undefined) {
          this.productRisk.vehicleInfos.acquisitionDate = this.parseDate(v);
        }
      },
      
    }, */
  /*   acquisitionDate: {
      get: function () { return this.productRisk.vehicleInfos.acquisitionDate },
      set: function (v) {
        if (v != undefined) {
          this.productRisk.vehicleInfos.acquisitionDate = v;

        }
      },
    }, */
    isVehicleSelected() { return this.productRisk.vehicle.id != "-1" },

  },
  methods: {
    async getVehicleItemsLists() {
      console.log('getVehiclesI');
      const acquisitionModesReq = axios.get(
        "/api/listItems/acquisitionModes"
      );
      const usesReq = axios.get("/api/listItems/uses");
      const tWeightsReq = axios.get("/api/listItems/trailerWeights");
      const tTypesReq = axios.get("/api/listItems/trailerTypes");
      const vehicleOwnerReq = axios.get("/api/listItems/vehicleOwnerTypes");
      const withoutInsuranceRangesReq = axios.get(
        "/api/listItems/withoutInsuranceRanges"
      );

      try {
        this.selectsLoading = true;
        const responses = await axios.all([
          acquisitionModesReq,
          usesReq,
          tWeightsReq,
          tTypesReq,
          vehicleOwnerReq,
          withoutInsuranceRangesReq,
        ]);

        this.itemsLists['acquisitionModes'] = responses[0].data.listItems;
        this.itemsLists['uses'] = responses[1].data.listItems;
        this.itemsLists['trailerWeights'] = responses[2].data.listItems;
        this.itemsLists['trailerTypes'] = responses[3].data.listItems;
        this.itemsLists['vehicleOwner'] = responses[4].data.listItems;
        this.itemsLists['withoutInsuranceRanges'] = responses[5].data.listItems;

        this.selectsLoading = false;
      } catch (error) {
        console.log(error);
        this.selectsLoading = false;
      }


    },
    async getCitiesListForAuto() {
      try {
        this.citiesLoading = true;
        const response = await axios
          .get("/api/listItems/citiesByZipCodeForAuto", {
            params: {
              zipCode: this.productRisk.vehicleInfos.parkDetails.zipCode,
              productCode: Vue.prototype.$codeAuto,
            },
          });

        this.itemsLists['cities'] = response.data.listItems;

        this.citiesLoading = false;
      } catch (error) {
        this.productRisk.vehicleInfos.parkDetails.city = [];
        this.itemsLists.cities = [];

        this.citiesLoading = false;
      }

    },
    updateVehicle(vehicle) {
      this.searchVehicleDialog = false;
      this.productRisk.vehicle = vehicle;
    },

    nextStep() {
      this.$refs["observerVehicle"].validate().then((valid) => {
        if (valid) {
          console.log(this.risk)
          this.$store.commit('estimation/nextRiskStep')
        }
      });
    },

    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString();
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    /* "productRisk.vehicleInfos.parkDetails.zipCode": {
      handler(val) {
        //logger.info(this.$refs.clientValidator.validate() && this.$refs.clientValidator);
        if(val === null || val === ''){
          this.productRisk.vehicleInfos.parkDetails.city = "";
          this.itemsLists['cities'] = []
        } else  this.getCitiesListForAuto();
      },
      deep: true,
      immediate: true
    }, */
    'productRisk.vehicleInfos.acquisitionDate': {
      handler(val) {
        this.formatedAcquisitionDate = this.formatDate(val);
      },
    },
    'productRisk.vehicleInfos.firstUseDate': {
      handler(val) {
        this.formatedFirstUseDate = this.formatDate(val);
      },
    }
  },

};
</script>
