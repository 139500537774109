<template lang="pug">
  v-row(justify='center')
    v-col(cols='12' sm='4')
      v-skeleton-loader(v-if='firstLoad' type='card-heading, list-item@4' height='380')
      v-card.mt-8.pb-3(v-if='!firstLoad && tokenIsValid && !passwordReseted' v-show='!firstLoad')
        v-card-title.justify-center
          v-img(lazy-src='' max-height='48' max-width='48' :src='require(`@/assets/images/logo_white.png`)' )
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Changer mon mot de passe
          br
          | Modifier mon mot de passe actuel pour un nouveau. Au minimum 8 caractères. Avec au moins un caractère spécial (!,%,&,@,#,$,^,*,?,_,~),
          |       une minuscule, une majuscule et un chiffre
        validation-observer(ref='passwordObserver' v-slot='{ invalid }')
          v-form(@submit.prevent='resetMyPassword')
            v-card-text
              validation-provider(v-slot='{ errors }'
                vid='password'
                name='nouveau mot de passe'
                rules='required|min:8|max:30|customPassword|notContainsForbiddenChars|confirmed:confirmedPassword')
                v-text-field(
                  v-model='password'
                  :error-messages='errors'
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append='showPassword = !showPassword'
                  :counter='8'
                  label='Nouveau mot de passe'
                )
              validation-provider(v-slot='{ errors }' vid='confirmedPassword' name='confirmez votre nouveau mot de passe')
                v-text-field(
                  v-model='confirmedPassword'
                  :error-messages='errors'
                  :append-icon="showconfirmedPassword ? 'visibility' : 'visibility_off'"
                  :type="showconfirmedPassword ? 'text' : 'password'"
                  @click:append='showconfirmedPassword = !showconfirmedPassword'
                  label='Confirmez votre nouveau mot de passe'
                )
            v-card-actions.text-right
              v-btn.button_color--text(:loading='loading' color='button' type='submit' :disabled='invalid')
                | modifier mon mot de passe actuel
      v-card.mt-8.pb-3(v-if='!firstLoad && !tokenIsValid' v-show='!firstLoad')
        v-card-title.justify-center
          v-img(lazy-src='' max-height='48' max-width='48' :src='require(`@/assets/images/logo_white.png`)' )
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Le token est invalide ou a expiré
      SnackBar(:show="showError" :message='message')
      v-card.mt-8.pb-5(v-if='!firstLoad && tokenIsValid && passwordReseted' v-show='!firstLoad')
        v-card-title.justify-center
          v-img(lazy-src='' max-height='48' max-width='48' :src='require(`@/assets/images/logo_white.png`)' )
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Votre mot de passe a bien été modifié.
          br
          | Vous allez être redirigé vers la page d'authentification.
      SnackBar(:show="showError" :message='message')
</template>

<script>
import { required, confirmed, max, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";

localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("confirmed", confirmed);
extend("max", max);
extend("min", min);

/// create custom error message for custom rule
extend("customPassword", {
  message: () =>
    `Le mot de passe doit contenir au minimum 8 caractères. Avec au moins un caractère spécial (!, %, &, @, #, $, ^, *, ?, _, ~), une minuscule, une majuscule et un chiffre`,
  validate: (value) => {
    const notTheseChars = /["'?&/<>\s]/;
    const mustContainTheseChars =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^*-]).{8,}$/;
    const containsForbiddenChars = notTheseChars.test(value);
    const containsRequiredChars = mustContainTheseChars.test(value);
    return containsRequiredChars && !containsForbiddenChars;
  },
});

extend("notContainsForbiddenChars", {
  message: () =>
    `Le mot de passe contient des caractères interdits:  " ' ? & / < > ou des espaces.`,
  validate: (value) => {
    const notTheseChars = /["'?&/<>\s]/;
    const containsForbiddenChars = notTheseChars.test(value);
    return !containsForbiddenChars;
  },
});

import SnackBar from "@/components/common/SnackBar";
import ResetPasswordService from "@/services/reset-password.service";

export default {
  name: "ResetPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar,
  },

  data: () => ({
    appDir: process.env.VUE_APP_NAME,
    firstLoad: true,
    loading: false,
    showError: false,
    message: "",
    showPassword: false,
    showconfirmedPassword: false,
    password: "",
    confirmedPassword: "",
    token: "",
    tokenIsValid: "",
    passwordReseted: false,
  }),
  mounted() {
    this.token = this.$route.params.token;
    this.checkMyToken();
  },
  methods: {
    async checkMyToken() {
      try {
        const response = await ResetPasswordService.checkToken(this.token);
        this.message = {
          type: response.success ? "success" : "error",
          message: response.message,
        };
        this.tokenIsValid = response.success;
        if (!response.success) {
          setTimeout(function () {
            location.href = "/login";
          }, 3500);
        }
        this.firstLoad = false;
      } catch (error) {
        this.message = {
          type: "error",
          message: error.response.data.message,
        };
        console.error(error);
      }
    },
    async resetMyPassword() {
      try {
        this.loading = true;
        let response = await ResetPasswordService.resetMyPassword(
          this.token,
          this.password,
          this.confirmedPassword
        );
        this.message = {
          type: response.success ? "success" : "error",
          message: response.message,
        };
        if (response.success) {
          this.passwordReseted = true;
          setTimeout(() => {
            this.$router.push("/login");
          }, 3500);
        }
        this.loading = false;
      } catch (error) {
        this.message = {
          type: "error",
          message: error.response.data.message,
        };
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
