<template>
  <div>
    <v-app-bar app fixed clipped-left height="80" elevation="10">
      <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')">
        <v-icon>menu</v-icon>
      </v-app-bar-nav-icon>

      <v-avatar tile>
        <router-link to="/">
          <v-img
            lazy-src=""
            max-height="48"
            max-width="48"
            v-if="darkMode == true"
            :src="require(`@/assets/images/logo_transparent.png`)"
          ></v-img>
          <v-img
            lazy-src=""
            max-height="48"
            max-width="48"
            v-else
            :src="require(`@/assets/images/logo_white.png`)"
          ></v-img>
        </router-link>
      </v-avatar>

      <v-toolbar-title class="mx-5">{{ $route.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-form
        @submit.prevent="submitSearch"
        class="pa-0 ma-0"
        style="display: inline-flex"
      >
      
        <v-select
          :items="searchItems"
          v-model="searchSelect"
          label="Rechercher un contrat ou un devis"
          outlined
          hide-details
          hide-selected
          open-on-clear
          solo
          dense
          hide-no-data
          item-text="text"
          item-value="id"
          class="select-box"
          :disabled="disabledCombobox"
        ></v-select>

        <v-text-field
          v-model="lastName"
          label=""
          outlined
          hide-details
          clearable
          clear-icon="clear"
          open-on-clear
          small-chips
          deletable-chips
          solo
          dense
          hide-no-data
          append-icon=""
          class="shrink mx-1"
          :disabled="disabledCombobox"
        >
        </v-text-field>

        <v-btn
          color="button"
          class="button_color--text"
          height="40"
          :disabled="disabledCombobox"
          type="submit"
        >
          <v-icon left>search</v-icon>Rechercher
        </v-btn>
      </v-form>

      <AccountMenu v-on:childToParent="onChildClick"></AccountMenu>
    </v-app-bar>
  </div>
</template>

<style scoped>
/* Add colored border bottom to top app bar */
::v-deep .v-toolbar__content {
  border-bottom: 4px solid var(--v-app_bar_bottom-base);
}
.select-box {
  max-width: 420px;
}
</style>

<script>
import AccountMenu from "@/components/layout/common/AccountMenu";

import axios from "axios";
import { mapState, mapActions } from "vuex";
export default {
  name: "AppBar",
  components: {
    AccountMenu,
  },
  data: () => ({
    appDir: process.env.VUE_APP_NAME,
    darkMode: false,
    profilMenu: true,

    user: {
      id: "",
      firstName: "",
      lastName: "",
      userName: "",
      initials: "",
      email: "",
      isEnabled: false,
      userBrokerId: null,
    },

    searchSelect: "searchNumber",
    searchItems: [
      { id: "searchNumber", text: "Rechercher un contrat ou un devis par numéro" },
      { id: "lastName", text: "Rechercher un contrat ou un devis par nom" },
    ],
    lastName: "",
    menuProps: {
      disabled: true,
    },
    disabledCombobox: false,
  }),
  async created() {
    this.$store.dispatch("getAgency");
  },
  mounted() {},
  computed: {
    switchDarkModeLabel: function () {
      return this.darkMode ? "Light" : "Dark";
    },
    initials() {
      return this.$store.state.auth.data.user.initials;
    },
    userName() {
      return this.$store.state.auth.data.user.userName;
    },
  },
  methods: {
    onChildClick(value) {
      this.darkMode = value;
    },
    submitSearch() {
      if (this.$route.name != "Recherche de devis et contrats") {
        if (this.searchSelect && this.lastName) {
          this.$router.push(
            "/contract/topSearch?" +
              this.searchSelect +
              "=" +
              this.lastName +
              ""
          );
        }
      }
    },
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    },
    logout() {
      // this.$router.push( {path: '/logout'} )
      window.location.href = "/logout";
    },
  },
  watch: {
    "$route.path": {
      handler() {
        if (
          this.$route.path == "/contract/topSearch" ||
          this.$route.path == "/contract/search"
        ) {
          this.disabledCombobox = true;
        } else {
          this.disabledCombobox = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
